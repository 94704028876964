/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptedForMasterLicenseTypeRequest } from '../models/AcceptedForMasterLicenseTypeRequest';
import type { AcceptedForMasterLicenseTypeResults } from '../models/AcceptedForMasterLicenseTypeResults';
import type { MasterLicenseTypeResults } from '../models/MasterLicenseTypeResults';
import type { StateBoardAcceptedLicenseResults } from '../models/StateBoardAcceptedLicenseResults';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LicenseTypeRouteService {

    /**
     * Get a list of Master License Types and their groups. A group is a collection of Master License Types. When requesting a verification, if there are multiple licenses requested they must all be part of the same group.
     * @returns MasterLicenseTypeResults Success
     * @throws ApiError
     */
    public static getApiV1LicenseTypes(): CancelablePromise<Array<MasterLicenseTypeResults>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/licenseTypes',
        });
    }

    /**
     * Get a list of all accepted license types for a specific Master License Type ID
     * @returns AcceptedForMasterLicenseTypeResults Success
     * @throws ApiError
     */
    public static getApiV1LicenseTypesAcceptedForMasterLicenseType({
masterLicenseTypeId,
}: {
/**
 * Master License Type ID
 */
masterLicenseTypeId: number,
}): CancelablePromise<Array<AcceptedForMasterLicenseTypeResults>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/licenseTypes/AcceptedForMasterLicenseType/{masterLicenseTypeId}',
            path: {
                'masterLicenseTypeId': masterLicenseTypeId,
            },
        });
    }

    /**
     * Get State Boards that accept the submited Master License Type ID. Exclude the State Board IDs submitted.
     * @returns AcceptedForMasterLicenseTypeResults Success
     * @throws ApiError
     */
    public static postApiV1LicenseTypesAcceptedForMasterLicenseType({
requestBody,
}: {
requestBody: AcceptedForMasterLicenseTypeRequest,
}): CancelablePromise<Array<AcceptedForMasterLicenseTypeResults>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/licenseTypes/AcceptedForMasterLicenseType',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static getApiV1LicenseTypesBoardsAcceptingVerifications(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/licenseTypes/BoardsAcceptingVerifications',
        });
    }

    /**
     * @returns StateBoardAcceptedLicenseResults Success
     * @throws ApiError
     */
    public static getApiV1LicenseTypesBoardsProvidingVerifications(): CancelablePromise<Array<Array<StateBoardAcceptedLicenseResults>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/licenseTypes/BoardsProvidingVerifications',
        });
    }

}
