import { Route, Routes } from "@solidjs/router";
import { Component } from "solid-js";
import { OpenAPI } from "./ApiRequests";
import AcceptingStatesPage from "./Pages/AcceptingStates";
import ContactUsPage from "./Pages/ContactUs";
import FaqPage from "./Pages/Faq";
import { HomePage } from "./Pages/HomePage";
import OverviewPage from "./Pages/Overview";
import RegisteredStatesPage from "./Pages/RegisteredStates";
import TransactionComplete from "./Pages/TransactionComplete";

const App: Component = () => {
  if (import.meta.env.MODE === "production") {
    OpenAPI.BASE = "https://veridoc.org";
  } else if (import.meta.env.MODE === "test") {
    OpenAPI.BASE = "https://test.veridoc.org";
  } else {
    OpenAPI.BASE = "http://localhost:5091";
  }

  return (
    <>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:startsWithIndex" element={<HomePage />} />
          <Route path="/test" element={<HomePage />} />
          <Route path="/search" element={<HomePage viewState={1} />} />
          <Route path="/thankyou/:id" element={<TransactionComplete />} />
          <Route path="/overview" element={<OverviewPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/acceptingstates" element={<AcceptingStatesPage />} />
          <Route path="/registeredstates" element={<RegisteredStatesPage />} />
          <Route path="/contactus" element={<ContactUsPage />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
